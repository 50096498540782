import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { omit } from 'lodash';
import {
  DistributionConfig,
  IPatchProductProfile,
  ProductProfile,
} from '../../../../../../types';
import { DistributionConfigForm } from './DistributionConfigForm';
import {
  deserializeProductProfileDistributionConfigs,
  serializeProductProfileDistributionConfig,
} from '../../../../../../helpers';
import { sendNotification } from '../../../../../../../../store/notifications/actions';
import { api } from '../../../../../../../../libs/helpers';

interface ProductProfileDistributionConfigsProps {
  productProfile: ProductProfile;
  onPatchProductProfile: IPatchProductProfile;
}

export const ProductProfileDistributionConfigs: FC<ProductProfileDistributionConfigsProps> = ({
  productProfile,
  onPatchProductProfile,
}) => {
  const dispatch = useDispatch();
  const [distributionConfig, setDistributionConfig] = useState<
    DistributionConfig | undefined
  >(productProfile.destination);
  useEffect(() => {
    setDistributionConfig(productProfile.destination);
  }, [productProfile]);
  const onSubmitForm = async (
    values: Partial<DistributionConfig>,
    onSuccess?: Function,
    onFail?: Function
  ) => {
    try {
      const method = productProfile.destinationUuid ? 'patch' : 'post';
      let body = serializeProductProfileDistributionConfig(values);
      const url = `destination${
        productProfile.destinationUuid
          ? `/${productProfile.destinationUuid}`
          : ''
      }`;
      if (method === 'patch') {
        if (values.credentials?.keyFiles?.length === 0) {
          body = omit(body, 'credentials.key_files');
        }
      }
      const { data: destinationResponse } = await api()[method](url, body);
      const newDestination = deserializeProductProfileDistributionConfigs(
        destinationResponse
      );
      if (method === 'patch' && destinationResponse?.uuid) {
        setDistributionConfig(newDestination);
        sendNotification({
          text: 'The Product Profile has been successfully updated!',
          success: true,
        })(dispatch);
      } else if (method === 'post' || !productProfile.destinationUuid) {
        await onPatchProductProfile({
          destination_uuid: destinationResponse.uuid,
        });
      }
      if (onSuccess) onSuccess(newDestination);
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong updating the destination config!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };

  const onTestConnection = async (values: Partial<DistributionConfig>) => {
    try {
      await api().post(
        'destination/test',
        serializeProductProfileDistributionConfig(values)
      );
      sendNotification({
        text: 'Connection has been successfully established!',
        success: true,
      })(dispatch);
    } catch (err) {
      const text =
        JSON.stringify(err?.response?.data?.detail) ||
        'Oops! Something went wrong updating the destination config!';
      sendNotification({
        text,
        success: false,
      })(dispatch);
    }
  };
  return (
    <>
      <Row>
        <Col>
          <DistributionConfigForm
            onSubmit={onSubmitForm}
            onTestConnection={onTestConnection}
            distributionConfig={distributionConfig}
          />
        </Col>
      </Row>
    </>
  );
};
