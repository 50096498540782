import React, {
  ChangeEventHandler,
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { cloneDeep, get, omit, pick, set } from 'lodash';
import { DistributionConfig } from '../../../../../../types';
import { readFileAsText } from '../../../../../../../../libs/helpers';

interface DistributionConfigFormProps {
  onSubmit: (values: Partial<DistributionConfig>, onSuccess?: Function) => void;
  distributionConfig?: DistributionConfig;
  onTestConnection: (
    values: Partial<DistributionConfig>,
    onSuccess?: Function
  ) => void;
}

export const DistributionConfigForm: FC<DistributionConfigFormProps> = ({
  onSubmit,
  distributionConfig,
  onTestConnection,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [sshKeyTouched, setSshKeyTouched] = useState(false);
  const [testDisabled, setTestDisabled] = useState<boolean>(false);
  const defaultValues: Partial<DistributionConfig> = {
    credentials: {
      username: '',
      keyFiles: [],
    },
    hostname: '',
    port: 22,
    options: {
      keySpec: '',
      keyValue: '',
    },
  };
  const [values, setValues] = useState<Partial<DistributionConfig>>(
    (distributionConfig && {
      ...distributionConfig,
      options: {
        keyValue: `${
          distributionConfig.options?.keySpec
            ? `${distributionConfig.options?.keySpec} `
            : ''
        }${distributionConfig.options?.keyValue || ''}`,
      },
    }) ||
      defaultValues
  );

  useEffect(() => {
    if (distributionConfig) {
      setValues({
        ...distributionConfig,
        ...(distributionConfig.uuid && {
          credentials: {
            username: distributionConfig.credentials.username || '********',
            keyFiles: ['***************************************'],
          },
        }),
        options: {
          keyValue: `${
            distributionConfig.options?.keySpec
              ? `${distributionConfig.options?.keySpec} `
              : ''
          }${distributionConfig.options?.keyValue || ''}`,
        },
      });
    }
  }, [distributionConfig]);

  useEffect(() => {
    const valuesToCheck = [
      'hostname',
      'port',
      'credentials.username',
      'options.keyValue',
      ...(sshKeyTouched ? ['credentials.keyFiles[0]'] : []),
    ];
    setTestDisabled(() => {
      let disabled = false;
      disabled = valuesToCheck.some((key) => {
        const valueToCheck = get(values, key);
        return !valueToCheck;
      });
      if (!disabled) {
        if (values.options?.keyValue) {
          const [keySpec, keyValue] = values.options.keyValue
            .split(' ')
            .map((str) => str.trim());
          if (!keySpec || !keyValue) {
            disabled = true;
          }
        }
      }
      return disabled;
    });
  }, [values, sshKeyTouched]);

  const uploadInputRef = useRef<HTMLInputElement>(null);

  const toggleEditMode = (newValues?: Partial<DistributionConfig>) => {
    setEditMode((prev) => {
      if (prev) {
        setValues(
          (newValues && {
            ...newValues,
            credentials: {
              username: newValues.credentials?.username || '********',
              keyFiles: ['***************************************'],
            },
            options: {
              keyValue: `${
                newValues.options?.keySpec
                  ? `${newValues.options?.keySpec} `
                  : ''
              }${newValues.options?.keyValue || ''}`,
            },
          }) ||
            (distributionConfig && {
              ...distributionConfig,
              credentials: {
                username: distributionConfig.credentials.username || '********',
                keyFiles: ['***************************************'],
              },
              options: {
                keyValue: `${
                  distributionConfig.options?.keySpec
                    ? `${distributionConfig.options?.keySpec} `
                    : ''
                }${distributionConfig.options?.keyValue || ''}`,
              },
            }) ||
            defaultValues
        );
      }
      setSshKeyTouched(false);
      return !prev;
    });
  };
  const onChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    const newValues = set(cloneDeep(values), ev.target.name, ev.target.value);
    setValues(newValues);
  };

  const onConfirmClick = () => {
    let dataToSend = cloneDeep(values);
    if (dataToSend) {
      if (values.options?.keyValue) {
        const [keySpec, keyValue] = values.options.keyValue
          .split(' ')
          .map((str) => str.trim());
        if (keySpec && keyValue) {
          dataToSend = set(dataToSend, 'options.keyValue', keyValue);
          dataToSend = set(dataToSend, 'options.keySpec', keySpec);
        }
      }
      if (!sshKeyTouched) {
        dataToSend = omit(dataToSend, 'credentials.keyFiles');
      }
      onSubmit(dataToSend, (receivedData: Partial<DistributionConfig>) => {
        toggleEditMode(receivedData);
      });
    }
  };

  const onUploadClick = () => {
    if (uploadInputRef.current) uploadInputRef.current.click();
  };
  const onUploadSshKey: ChangeEventHandler<HTMLInputElement> = async (ev) => {
    if (ev.target.files) {
      const sshKey = ev.target.files[0];
      const sshKeyText = await readFileAsText(sshKey);
      setValues({
        ...values,
        credentials: {
          ...values.credentials,
          username: values.credentials?.username || '',
          keyFiles: [sshKeyText],
        },
      });
      set(uploadInputRef, 'current.value', null);
      setSshKeyTouched(true);
    }
  };

  const onTestConnectionClick = () => {
    let dataToSend = cloneDeep(
      pick(values, ['port', 'hostname', 'credentials', 'options', 'uuid'])
    );
    if (dataToSend) {
      if (values.options?.keyValue) {
        const [keySpec, keyValue] = values.options.keyValue
          .split(' ')
          .map((str) => str.trim());
        if (keySpec && keyValue) {
          dataToSend = set(dataToSend, 'options.keyValue', keyValue);
          dataToSend = set(dataToSend, 'options.keySpec', keySpec);
        }
      }
      if (!sshKeyTouched) {
        dataToSend = omit(dataToSend, 'credentials.keyFiles');
      }
      onTestConnection(dataToSend);
    }
  };
  return (
    <div id={'DistributionConfigForm'}>
      <div className="form-content mt-4 pb-5">
        <h6 className={'purple-text mb-4'}>SFTP Destination</h6>
        <Row>
          <Col xl={12} lg={12}>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label className="pki-label" for="credentials.username">
                    Username
                  </Label>
                  <Input
                    type={'text'}
                    disabled={!editMode}
                    id={'credentials.username'}
                    value={values.credentials?.username || ''}
                    name={'credentials.username'}
                    onChange={onChange}
                  />
                  <FormFeedback>Cannot be empty</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label className="pki-label" for="hostname">
                    Host Name
                  </Label>
                  <Input
                    type={'text'}
                    disabled={!editMode}
                    value={values.hostname || ''}
                    id={'hostname'}
                    name={'hostname'}
                    onChange={onChange}
                  />
                  <FormFeedback>Cannot be empty</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label className="pki-label" for="port">
                    Port
                  </Label>
                  <Input
                    type={'number'}
                    disabled={!editMode}
                    min={1}
                    max={65535}
                    value={values.port}
                    name={'port'}
                    onChange={onChange}
                  />
                  <FormFeedback>Cannot be empty</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col xl={5} lg={12}>
            <div className={'m-0'}>
              <FormGroup>
                <Label className="pki-label" for="sshKey">
                  SSH User Key
                </Label>
                <div className={'d-flex'}>
                  <Input
                    type={'password'}
                    disabled={true}
                    id={'sshKey'}
                    placeholder={`Please upload the private key beginning with -----BEGIN EC PRIVATE KEY----- and ends with -----END EC PRIVATE KEY-----`}
                    value={values.credentials?.keyFiles[0] || ''}
                    name={'credentials.keyFiles[0]'}
                    onChange={onChange}
                  />
                  <input
                    type={'file'}
                    hidden={true}
                    ref={uploadInputRef}
                    id={'uploadSshKeyInput'}
                    name={'uploadSshKeyInput'}
                    onChange={onUploadSshKey}
                  />
                  <Button
                    disabled={!editMode}
                    outline
                    className={'ml-2'}
                    onClick={onUploadClick}
                  >
                    Upload
                  </Button>
                </div>
              </FormGroup>
            </div>
          </Col>
          <Col xl={7} lg={12}>
            <FormGroup>
              <Label className="pki-label" for="options.keyValue">
                SSH Host Key
              </Label>
              <Input
                type={'text'}
                disabled={!editMode}
                id={'options.keyValue'}
                value={
                  distributionConfig?.uuid && !editMode
                    ? `${distributionConfig.options?.keySpec || ''} ${
                        distributionConfig.options?.keyValue || ''
                      }`
                    : `${values.options?.keyValue || ''}`
                }
                onChange={onChange}
                placeholder={
                  'Example: ssh-ed25519 AAAAC3NzaC1lZDI1NTE5AAAAIMqVqaYecpNKjkutUWdXV3dFXRi5eqpMJ0jqJKtsfXg8'
                }
                name={'options.keyValue'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className={'mb-3'}></Row>
        {onSubmit !== undefined && (
          <div className="modal-buttons">
            <div className="float-right mt-5 pb-5 d-flex">
              {!editMode ? (
                <>
                  <Button
                    id={`edit-distribution-config`}
                    outline
                    onClick={() => toggleEditMode()}
                  >
                    Edit
                  </Button>
                </>
              ) : (
                <>
                  <div>
                    <Button
                      id={`test-distribution-config-form`}
                      outline
                      disabled={testDisabled}
                      onClick={onTestConnectionClick}
                      className={'mr-2'}
                    >
                      Test Connection
                    </Button>
                    <Button
                      id={`cancel-distribution-config-form`}
                      outline
                      onClick={() => toggleEditMode()}
                      className={'mr-2'}
                    >
                      Cancel
                    </Button>
                    <Button
                      id={`submit-distribution-config-form`}
                      onClick={onConfirmClick}
                      outline
                    >
                      Confirm
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
